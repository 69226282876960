const PUSHER_CLASS = 'js-gallery-pusher';

$('.gallery--deck').on('showing.jq.offie', '[data-toggle="offie"]', function (event) {
  let $eventTarget = $(event.target);

  // Render the "pusher" which will push content beneath the dropdown.
  if (0 === $eventTarget.find('.' + PUSHER_CLASS).length) {
    $eventTarget.append('<div class="' + PUSHER_CLASS + '" />');
  }

  // Animate to scroll height.
  $eventTarget.find('.' + PUSHER_CLASS).css({
    height: event.offie.getTarget()[0].scrollHeight,
  });
});

$('.gallery--deck').on('hiding.jq.offie', '[data-toggle="offie"]', function (event) {
  // Animate to 0 to simulate the dropdown animation.
  $(event.target).find('.' + PUSHER_CLASS).css({height: 0});
});

$('.gallery--deck [data-toggle="buckle"]').on('ajaxComplete.jq.buckle', function (event) {
  let $eventTarget = $(event.target);
  $eventTarget.find('.' + PUSHER_CLASS).css({
    height: $eventTarget.data('relatedOffieDropdown')[0].scrollHeight,
  });
});

$('.js-offie--buckle-content').on('showing.jq.offie', function (event) {
  let
    $buckleElement = $(event.target).closest('[data-toggle="buckle"]'),
    buckle = $buckleElement.length ? $buckleElement.data('buckle') : null
  ;

  if (buckle) {
    // Special case for buckle to update the height at the end of ajax call.
    $buckleElement.data('relatedOffieDropdown', event.offie.getTarget());

    // Trigger the buckle fetch.
    buckle.fetch({});
  }
});

$('.js-buckle--offie-content').on('ajaxComplete.jq.buckle', function (event) {
  let
    $eventTarget = $(event.target),
    offieInstances = $eventTarget.find('[data-toggle="offie"]')
  ;

  offieInstances.each(function(index, element) {
    let offie = $(element).data('offie');

    if (offie && offie.isOpened()) {
      offie.getTarget().css('height', 'auto');
    }
  });
});
